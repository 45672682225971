<script setup lang="ts">
import dayjs from "dayjs";

const emit = defineEmits<{
	(event: "close"): void;
}>();

const { t } = useT();
const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
const { exitFullscreen } = useAndroidFullscreenToggles();
const { open } = useAppModals();
const { piggyData, readyToBreak, isFullStatus, refreshPiggyData, handlePiggyClick } = usePiggy({
	open
});

const title = computed(() => (isFullStatus.value ? t("Break Your Piggy Bank!") : t("Get extra coins for every play!")));

const subTitle = computed(() =>
	isFullStatus.value ? t("It's full - you can collect free SC!") : t("Increase play level to grow your piggy faster!")
);

const buttonText = computed(() => {
	if (!isSweepStakes.value) {
		return t("Switch mode");
	}
	return readyToBreak.value
		? `Collect Now For $${piggyData.value?.promoOffersPresets?.money ?? 0}`
		: t("Play & Feed Piggy");
});

const handleOpenInfo = () => {
	exitFullscreen();
	open("LazyOModalPromoHowItWorks", { type: "piggyBank" });
};

const handleClick = () => {
	if (!isSweepStakes.value) {
		handleSwitchGameMode();
		return;
	}
	handlePiggyClick();
};

const handleClose = () => {
	emit("close");
};

const { durationLeft, reset } = useCountdown({
	timestamp: piggyData.value?.availableTimeToBreak || "",
	formatToken:
		dayjs(piggyData.value?.availableTimeToBreak ?? "").diff(dayjs(), "d") >= 1
			? "DD[ d ] HH[ h ]mm[ m ]"
			: "HH[ h ]mm[ m ]ss[ s ]",
	isOptimized: true,
	onCountdownStop: refreshPiggyData
});

watch(
	() => piggyData.value?.availableTimeToBreak,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<div :class="['piggy-card', { 'entries-mode': !isSweepStakes }]">
		<NuxtIcon name="20/close-pop" class="piggy-card-close" filled @click="handleClose" />
		<div class="piggy-head">
			<AText :size="16" :modifiers="['semibold']">{{ title }}</AText>
			<AText :size="14">{{ subTitle }}</AText>
		</div>

		<NuxtImg
			src="/nuxt-img/piggy-bank/piggy-banner-img.png"
			class="piggy-card-img"
			width="592"
			height="240"
			alt="piggy-banner-img"
			format="webp"
		/>

		<div v-if="isFullStatus" class="piggy-full-wrapper">
			<AText :size="14" class="text-constanta" :modifiers="['center']">
				<i18n-t keypath="Break now to get {icon}">
					<template #icon>
						<NuxtIcon name="20/info-pop" class="info-icon" filled @click.stop="handleOpenInfo" />
					</template>
				</i18n-t>
			</AText>
			<MPrizeFund variant="entries" img="/nuxt-img/prizes/supercoins@2x.png" :icon-size="22">
				<AText :size="24" as="h4" :modifiers="['semibold']">
					{{ numberFormat(piggyData?.maxAmount ?? 0) }}
				</AText>
				<AText :size="10" :modifiers="['uppercase']">
					<i18n-t keypath="Free {br} super coins">
						<template #br>
							<br />
						</template>
					</i18n-t>
				</AText>
			</MPrizeFund>
		</div>
		<MPiggyBankProgressBar
			v-else
			class="progress"
			:complete="piggyData?.moneyBoxAmount ?? 0"
			:goal="piggyData?.maxAmount ?? 0"
			withInfo
			@info="handleOpenInfo"
		/>

		<AText v-if="!isSweepStakes" :size="14" :modifiers="['center']">{{
			t("The Piggy Bank available only in SC mode.")
		}}</AText>

		<div class="actions-wrapper">
			<AText v-if="readyToBreak && piggyData?.availableTimeToBreak" :size="14" :modifiers="['center']">
				Break in
				<br />
				<AText :size="16" class="text-сirebon" :modifiers="['semibold']">{{ durationLeft }}</AText>
			</AText>
			<AButton v-else variant="primary" size="lg" :modifiers="['wide']" @click="handleClick">
				<AText :size="{ full: 18, md: 16 }" :modifiers="['medium']">{{ buttonText }}</AText>
			</AButton>

			<AText v-if="isSweepStakes && !isFullStatus" :size="14" :modifiers="['center']">{{
				t("You can break piggy at any time")
			}}</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.piggy-card {
	position: relative;
	border-radius: 16px;
	border: 2px solid var(--celaya);
	background: var(--carabanchel);
	padding: 16px;
	flex: 1 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&.entries-mode .progress {
		opacity: 0.5;
	}

	.piggy-card-close {
		position: absolute;
		font-size: 20px;
		top: 12px;
		right: 16px;
		cursor: pointer;
	}

	.info-icon {
		font-size: 20px;
		cursor: pointer;

		:deep(svg path) {
			fill: var(--ciampea);
		}
	}

	.piggy-full-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
	}

	.piggy-head {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.actions-wrapper {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.piggy-card-img {
		margin: 0 auto;
		width: 296px;
		height: 120px;
		object-fit: cover;
	}

	&:deep(.prize) {
		justify-content: center;
	}
}
</style>
